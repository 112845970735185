import React from 'react'
import '../../App.css';

const Urlaube = () => {
  return (
    <div className='pageStyle'>
    <div className='global-styles'> 
       
      <h1 className='headingStyle'>Urlaub 2024</h1>
        <div className='flex-container'><br />

          <div className='flex-item'>
              <div className='text-style'>
              <ul>
                <li>01.01.2024 - 04.01.2024</li>
                <li>05.02.2024 - 08.02.2024</li>
                <li>25.03.2024 - 28.03.2024</li>
                <li>15.04.2024 - 16.04.2024</li>
                <li>02.05.2024</li>
                <li>25.05.2024 - 30.05.2024</li>
                <li>27.07.2024 - 10.08.2024</li>
                <li>28.10.2024 - 31.10.2024</li>
                <li>23.12.2024 - 02.01.2025</li>
                </ul>
              </div>
          </div>
        </div>
    </div>
  </div>
  )
}

export default Urlaube;