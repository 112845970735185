import React, {useEffect} from 'react';
import '../../App.css';
import '../styles/faqs.css'
//import GoogleMapReact from 'google-map-react';

const Questions = () =>{

    useEffect(() => {
        window.scrollTo(0, 0);
      }); 

    return (
        <div className='pageStyle'>
            <div className='global-styles'> 
                <h1 className='headingStyle'>FAQs</h1>
                <div className='flex-container'><br />
                    <div className='flex-item'>
                    
                    <h2>Muss eine FFP2-Maske getragen werden?</h2>
                         <div className='text-style'>
                            Ja. <br></br>Ab 01.06.2022 kommt es aufgrund der aktuellen Novelle zur 2. COVID-19 Basismaßnahmenverordnung zu einem weitgehenden Entfall der 
                            Maskenpflicht. Für Ordinationen und Krankenanstalten bleibt die Maskenpflicht jedoch aufrecht. <br />
                            <a href="https://www.ris.bka.gv.at/Dokumente/BgblAuth/BGBLA_2022_II_201/BGBLA_2022_II_201.html" target="_blank" rel="noreferrer">Link</a>
                        </div>
                    </div>
                    <div className='flex-item'>
                    <h2>Wird ein 3G-Nachweis benötigt?</h2>
                        <div className='text-style'>
                            Nein. <br></br> Aufgrund der derzeitigen Infektionslage wird auf einen 3G-Nachweis bis auf weiteres verzichtet. 
                        </div>
                    </div>

                    <div className='flex-item'>
                    <h2>Wird ein Termin benötigt?</h2>
                        <div className='text-style'>
                            Ja.<br></br>Die Terminvergabe erfolgt ausschließlich telefonisch.
                        </div>
                    </div>

                    <div className='flex-item'>
                    <h2>Brauche ich eine Überweisung ?</h2>
                        <div className='text-style'>
                            Nein.<br></br>Eine Überweisung ist nur für eine OP-Freigabe, sowie für eine schriftliche Befundung unbedingt erforderlich!                        </div>
                    </div>

                    <div className='flex-item'>
                    <h2>Kann ich Medikamente telefonisch bestellen?</h2>
                        <div className='text-style'>
                            Ja. <br></br>Ausschließlich Medikamente, welche von uns verordnet wurden. Durch die Einführung des e-Rezeptes (elektronisches Rezept) werden 
                            Verordnungen in elektronischer statt in Papierform ausgestellt. Diese werden auf ihrer e-card als e-Rezept gespeichert. Sie können
                            dann in einer Apotheke ihrer Wahl die Medikamente abholen. Auch wenn Sie aus der ELGA (elektronische Gesundheitsakte) ausgetreten sind,
                            können e-Rezepte problemlos ausgestellt werden.
                        </div>
                    </div>

                    <div className='flex-item'>
                    <h2>Kann ich mich krankmelden?</h2>
                        <div className='text-style'>
                            Nein. <br></br>Krankmeldungen sind ausschließlich durch ihren Hausarzt möglich.
                        </div>
                    </div>

                    <div className='flex-item'>
                    <h2> Ich habe meinen Termin nicht eingehalten. Muss ich einen Kostenersatz leisten?</h2>
                        <div className='text-style'>
                            Ja. <br></br>Können Sie vereinbarte Termine zu untenstehenden Leistungen nicht wahrnehmen, ersuchen wir Sie diese 24 Stunden vorher
                            abzusagen, da wir bei Nichteinhaltung des Termins ein Ausfallshonorar in der Höhe von Euro 70,-- verrechnen müssen. <br></br><br></br><ul><strong>Dies betrifft folgende Leistungen:</strong><br></br>
                            <li>Ergometrie (Belastungs-EKG)</li><li>24h-EKG</li><li>24h-Blutdruck</li><li>ABI-Messung</li></ul><br></br>
                        </div>
                    </div>

                    <div className='flex-item'>
                    <h2>Gibt es eine Parkmöglichkeit ?</h2>
                        <div className='text-style'>
                           Ja. <br></br>Direkt vor unserer Ordination, stehen Kurzparkplätze gebührenpflichtig zur Verfügung.
                        </div>
                    </div>

                    <div className='flex-item'>
                    <h2>Verfügt die Ordination über einen barrierefreien Zugang ?</h2>
                        <div className='text-style'>
                            Ja. <br></br>Für Patienten mit Gehbehinderung steht ein Treppenlift zur Verfügung.
                        </div>
                    </div>

                </div>
            </div>
        </div>
     



    )
}

export default Questions;