import React, { useEffect, useRef } from "react";
import '../App.css';
import './HeroSection.css';
import myVideo from '../videos/video-4.mp4';

function HeroSection() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  
  return (
    <div className='hero-container'>
      <video 
        src={myVideo} 
        autoPlay 
        loop
        muted 
        controls
        alt="All the devices"
        ref={videoEl}
      />
      <h1>Dr. Thomas Enzelsberger</h1>
      <p>Herzlich Willkommen in meiner Ordination!</p>
    </div>
  );
}

export default HeroSection;