import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Über uns</h2>
            <Link to='/termsofservice'>Impressum</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Kontakt</h2>
            <Link to='/Kontakt'>Kontakt</Link>
            <Link to='/Urlaube'>Urlaube</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Unsere Ordination</h2>
            <Link to='/Ordination'>Unsere Ordination</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/facebook'>Facebook</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              Dr. Thomas Enzelsberger
              <i class='fas fa-heartbeat' />
            </Link>
          </div>
          <small class='website-rights'>Softwaresolution © 2022</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              rel="noreferrer"
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;