import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Ordination from './components/pages/ordination';
import Footer from './components/Footer'
import Questions from './components/pages/Questions';
import contact from './components/pages/contact'
import karriere from './components/pages/Karriere';
import TermsOfService from './components/pages/TermsofService';
import facebook from './components/pages/Facebook';
import Testpage from './components/pages/Testpage';
import Urlaube from './components/pages/Urlaube';



const App = () => {

  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/services' component={Services} />
          <Route path='/Ordination' component={Ordination} />
          <Route path='/Faqs' component={Questions} />
          <Route path='/karriere' component={karriere} />
          <Route path='/termsofservice' component={TermsOfService} />
          <Route path='/kontakt' component={contact} />
          <Route path='/facebook' component={facebook} />
          <Route path='/kontakt' component={contact} />
          <Route path='/Testpage' component={Testpage} />
          <Route path='/Urlaube' component={Urlaube} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;