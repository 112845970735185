import React, {useEffect} from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

const Home = () =>{
    useEffect(() => {
        window.scrollTo(0, 0);
      });

    return (
        <div className='pageSytle'>
            <HeroSection />
       </div>
    )
}

export default Home;