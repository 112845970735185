import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  }
  const closeMobileMenu = () => {
    setClick(false);
  }


  return (
    <>
      <div className='NavContainer'> 
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            
            <i class="fas fa-heartbeat"></i>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/services'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Leistungen
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/Ordination'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Unsere Ordination
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/Kontakt'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Kontakt
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/faqs'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                FAQs
              </Link>
            </li>

          </ul>
        
        </div>
      </nav>
      </div>
    </>
  );
}

export default Navbar;