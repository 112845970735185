import React, {useEffect} from 'react';
import '../../App.css';
import '../../Impressum.css';

function TermsOfService () {

    useEffect(() => {
        window.scrollTo(0, 0);
      });

    return (
        <div className='pageStyle'>
            <div className='text-style-1'>
                <div><h1>Impressum</h1></div>

                    <div>Verantwortlich für den Inhalt dieser Seiten:</div><br />

                    <div>Information gemäß §5 E-Commerce-Gesetz und Offenlegung gemäß § 25 Mediengesetz</div><br />

                    <div>Diensteanbieter und Medieninhaber:</div>
                    <div>Dr. Thomas Enzselsberger</div>
                    <div>Ferdinand Porsche Ring 11</div><br />
                    
                    <div>2700 Wiener Neustadt</div>
                    <div>+43 2622 22633</div>
                    <div>www.internist-enzelsberger.at</div>
                    <div>ordination@enzelsberger.at</div><br />

                    <div>Mitglied der Ärztekammer Niederösterreich, Arztnummer 196851</div><br />

                    <div>Berufsbezeichnung: Facharzt für Innere Medizin</div><br />

                    <div>Tätigkeit unterliegt dem Ärztegesetz (http://www.ris.bka.gv.at/bundesrecht)</div><br />

                    <div>Mitglied der Ärztekammer für Niederösterreich</div>
                    <div>Berufsbezeichnung: Facharzt für Innere Medizin (verliehen in Österreich)</div>
                    <div>Tätigkeit unterliegt dem Ärztegesetz 1998</div>
                    <div>(siehe /www.ris.bka.gv.at/bundesrecht)</div><br />

                    <div>Fotos: Dr. Thomas Enzselsberger</div><br />

                    <div>Konzeption und technische Umsetzung</div>
                    <div>Ralph-Peter Zillbauer, Softwaresolution</div><br />

                    <div className='text-style-2'>
                        <div><h3>Haftungsausschluss</h3></div><br />

                        <div><h4>1. Inhalt des Onlineangebotes</h4></div><br />

                        <div>
                        Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen 
                        den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die 
                        Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich 
                        vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile 
                        der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig 
                        einzustellen.
                        </div><br />

                        <div><h4>2. Verweise und Links</h4></div>
                        <div>
                        Bei direkten oder indirekten Verweisen auf fremde Webseiten („Hyperlinks“), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine 
                        Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar 
                        wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen 
                        Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten/verknüpften 
                        Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller verlinkten /verknüpften Seiten, die nach 
                        der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge 
                        in vom Autor eingerichteten Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen anderen Formen von Datenbanken, auf deren Inhalt 
                        externe Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung 
                        solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die 
                        jeweilige Veröffentlichung lediglich verweist.
                        </div><br />
                        <div><h4>3. Urheber- und Kennzeichenrecht</h4></div>
                        <div>
                        Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm 
                        selbst erstellte Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte
                        zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den 
                        Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist 
                        nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte 
                        bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen 
                        oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet. Sollten gewisse Elemente meiner Homepage gegen irgendein Urheberrecht 
                        verstoßen oder Personen verletzen, bitte ich um eine sofortige Mitteilung damit das betreffende Element entfernt werden kann. Wer ohne Benachrichtigung den
                        Rechtsweg beschreitet handelt rechtswidrig gegen mich (Rechtsmissbrauch).
                        </div><br />
                        <div><h4>4. Datenschutz</h4></div>
                        <div>
                        Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt 
                        die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist – soweit 
                        technisch möglich und zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen 
                        des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie Emailadressen durch Dritte zur 
                        Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche Schritte gegen die Versender von so genannten Spam-Mails bei 
                        Verstößen gegen dieses Verbot sind ausdrücklich vorbehalten.
                        </div><br />
                        <div><h4>5. Rechtswirksamkeit dieses Haftungsausschlusses</h4></div>
                        <div>
                        Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen 
                        dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und 
                        ihrer Gültigkeit davon unberührt.
                        </div><br />
                    </div>
            </div>
        </div>
    )
}

export default TermsOfService;