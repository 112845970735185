import React from 'react';
import '../../App.css';

function karriere () {
    return (
        <div className='pageStyle'>
            <div className='global-styles'>
                    <h1>Offen Stellen</h1>

                    
            </div>    
        </div>
    )
}

export default karriere;