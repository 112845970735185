import React from 'react';
import '../../App.css';

const facebook = () => {
    const handleChange = () => {
        window.open("https://www.facebook.com/Internistische-Ordination-Dr-Thomas-Enzelsberger-1012666518855260", "_blank")
    }
    
    return (
        <div className='pageStyle'>
            <div className='globalStyles'>
                <div className='FacebookContainer'>
                    <div class='fab fa-facebook' onClick={handleChange}>Hier gelangen Sie auf unsere Facbook website</div>
                </div>
            </div>
        </div>
    )
}

export default facebook;