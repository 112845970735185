import React from 'react';
import '../../App.css';

function Testpage () {
    return (
        <div className='pageStyle'>
            <div className='global-styles'>
                <div className='mainContainer'>
                    <div className='Item'>
                        Item 1
                    </div>
                    <div className='Item'>
                        Item 2
                    </div>
                    <div className='Item'>
                        Item 3
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testpage;