import React, {useEffect} from 'react';
import { useState } from 'react';
import '../../App.css';
import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css';
import Pic12 from '../images/img-12.jpg'
import Pic13 from '../images/img-13.jpg'
import Pic14 from '../images/img-14.jpg'
import Pic15 from '../images/img-15.jpg'
import Pic16 from '../images/img-16.jpg'
import Pic17 from '../images/img-17.jpg'
import Pic18 from '../images/img-18.jpg'
import Pic19 from '../images/img-19.jpg'
import Pic20 from '../images/img-20.jpg'
import Pic21 from '../images/img-21.jpg'

export default function Ordination() {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [index, setIndex] = useState(0);
  let int = 3000; //Variable für Intervall Carousel Speed

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // Unser Ordination  
  return (
      <div className='pageStyle'>
          <div className='imageContainer'>
            
            <div className='CarouselContainer' id='CarouselContainer'>
            <Carousel variant='dark' activeIndex={index} onSelect={handleSelect} style={{zIndex:1}}>
              <Carousel.Item interval={int}>
                <img
                  className="ImgRespons2"
                  src={Pic13}
                  alt="Pic13"
                />
              </Carousel.Item>

              <Carousel.Item interval={int}>
                <img
                  className="ImgRespons2"
                  src={Pic21}
                  alt="Pic21"
                />

              </Carousel.Item>

              <Carousel.Item interval={int}>
                <img
                  className="ImgRespons2"
                  src={Pic14}
                  alt="Pic14"
                />

              </Carousel.Item>

              <Carousel.Item interval={int}>
                <img
                  className="ImgRespons2"
                  src={Pic15}
                  alt="Pic15"
                />

              </Carousel.Item>

              <Carousel.Item interval={int}>
                <img
                  className="ImgRespons2"
                  src={Pic18}
                  alt="Pic18"
                />


              </Carousel.Item>

              <Carousel.Item interval={int}>
                <img
                  className="ImgRespons2"
                  src={Pic19}
                  alt="Pic19"
                />

              </Carousel.Item>

              <Carousel.Item interval={int}>
                <img
                  className="ImgRespons2"
                  src={Pic12}
                  alt="Pic12"
                />


              </Carousel.Item>


              <Carousel.Item interval={int}>
                <img
                  className="ImgRespons2"
                  src={Pic20}
                  alt="Pic20"
                />

              </Carousel.Item>

              <Carousel.Item interval={int}>
                <img
                  className="ImgRespons2"
                  src={Pic16}
                  alt="Pic16"
                />

              </Carousel.Item>

              <Carousel.Item interval={int}>
                <img
                  className="ImgRespons2"
                  src={Pic17}
                  alt="Pic17"
                />

              </Carousel.Item>

          </Carousel>
          </div>
        </div>
      </div>

  )
   
  
  
}
