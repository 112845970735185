
import React from 'react';
import '../../App.css';
import Pic1 from '../images/Dr-Thomas-Enzelsberger.jpg'
import Pic11 from '../images/img-11.jpg'
import myVideo from '../../videos/video-4.mp4'

const contact = () => {

    return (
        <div className='pageStyle'>
            <div className='global-styles'>

            <div className='containerContactG'>
        
                <div className='containerC'>
                    <div className='containerCHeader'>
                        <h3>Dr. Thomas Enzelsberger</h3>
                    </div>
                    <div className='constainerCText'> 
                        2700 Wr. Neustadt <br></br>
                        Ferdinand Porsche Ring 11<br></br>

                        Tel:<a href='+43 2622 22633'>+43 2622 22633</a><br></br>
                        Fax:<a href='+43 2622 22633'>+43 2622 22633 4</a><br></br>
                    </div> 
                    <div className='containerCImage'>
                        <img className='ImgRespons' alt='Dr. Thomas Enzelsberger' src={Pic1} />
                    </div>
                </div>
        
                <div className='containerC'>
                    <div className='containerCHeader'>
                        <h3>Öffnungszeiten</h3>
                    </div>
                    <div className='constainerCText'> 
                        Alle Kassen <br></br>
                        <div className='DayTimeContainer'>
                            <div className='DayContainer'>
                                Mo: <br></br>
                                Di: <br></br>
                                Mi: <br></br>
                                Do: <br></br>
                            </div>
                            <div>
                                08:00 - 12:30; 14:00 - 17:00 <br></br>
                                08:00 - 12:30<br></br>
                                08:00 - 12:30<br></br>
                                08:00 - 12:30<br></br>
                            </div>
                        </div>
                    </div>
                    <div className='containerCImage'>
                        <video className='ContainerVideo' src={myVideo} autoPlay loop muted />
                    </div>
                </div>

            </div>
                <div className='ContainerLocation'>
                    <div className='containerCImage'>
                            <img className='ImgRespons' alt='GoogleMapsScreenshot' src={Pic11} />
                    </div>
                </div>
            </div>    
        </div>
        )
    }

export default contact;