import React, {useEffect} from 'react';
import '../../App.css';
import  '../../Services.css'
//<h1 className='services'>SERVICES</h1>
export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className='pageStyle'>
      <div className='global-styles'> 
         
        <h1 className='headingStyle'>Allgemeine Leistungen</h1>
          <div className='flex-container'><br />

            <div className='flex-item'>
              <h2>EKG</h2>
                <div className='text-style'>
                  An der rechten Vorhofwand des Herzens befindet sich der Sinusknoten. Er sendet elektrische Impulse, 
                  die sich über das Herz ausbreiten, damit es gleichmäßig schlägt. Beim Elektrokardiogramm (EKG) messen 
                  Elektroden, wie sich die Herzströme im Organ ausbreiten und zurückbilden. Die Elektroden sind an Brustkorb, 
                  Armen und Beinen befestigt und erfassen die elektrische Erregung an der Hautoberfläche. Diese Informationen geben 
                  sie an ein Aufzeichnungsgerät weiter. Auf einem Monitor werden sie schließlich grafisch als Wellenlinie dargestellt.
                </div>
            </div>

            <div className='flex-item'>
              <h2>24 Stunden EKG</h2>
                <div className='text-style'>
                Bei einem Langzeit-EKG beobachtet man die elektrische Herzaktivität über einen längeren Zeitraum. Dadurch erhält man 
                Aufschluss wie sich das Herz unter Alltagsbedingungen verhält. Meist ist eine Messung über ein Zeitfenster von 24 Stunden 
                ausreichend, um Herzrhythmusstörungen zu entdecken.
                </div>
            </div>

            <div className='flex-item'>
              <h2>Ergometrie (Belastungs-EKG)</h2>
                <div className='text-style'>
                Unter Ergometrie versteht man die Messung der körperlichen Leistungsfähigkeit unter Beobachtung bestimmter Körperfunktionen 
                mithilfe eines EKGs. Die Ergometrie wird auch als Belastungs-EKG bezeichnet. Neben dem Einsatz in der Sportmedizin kommt 
                die Ergometrie vor allem in der Kardiologie und Pulmologie zur Beurteilung von belastungsabhängigen Veränderungen der Herz- 
                und Lungentätigkeit zum Einsatz. Eine spezielle Vorbereitung ist nicht erforderlich. Eine Ergometrie sollte nicht
                mit nüchternem Magen erfolgen.
                </div>
            </div>

            
            <div className='flex-item'>
              <h2>Echokardiographie (Herzultraschall)</h2>
                <div className='text-style'>
                Die Ultraschallkardiographie (UKG), auch Herzultraschall genannt, unterstützt Ärzte bei der genauen Diagnose vorliegender 
                Herzprobleme. Es finden grundsätzlich zwei verschiedene Arten der Echokardiographie Anwendung: die häufiger eingesetzte 
                transthorakale und die transösophageale Echokardiographie.
                </div>
            </div>

            
            <div className='flex-item'>
              <h2>Kleine Spirometrie (Lungenfunktionsmessung)</h2>
                <div className='text-style'>
                Die Spirometrie (kleiner Lungenfunktionstest) ist ein diagnostisches Verfahren zur Messung der Lungenfunktion. Mit diesem 
                Lungenfunktionstest lassen sich verschiedene Parameter bestimmen, die Aufschluss über das Vorliegen einer 
                Atemflussbehinderung einerseits oder Veränderung von Lungenvolumina andererseits geben.
                </div>
            </div>

            <div className='flex-item'>
              <h2>C/V - Duplex (Ultraschall der Halsschlagadern)</h2>
                <div className='text-style'>
                Die Carotis-Duplex-Sonographie hilft dem Arzt dabei, verengte Gefäßstellen (Stenosen) zu finden, die das Risiko für einen 
                Schlaganfall erhöhen können. Es handelt sich um eine gängige Methode in der Diagnostik von Gefäßerkrankungen.
                </div>
            </div>
            <div className='flex-item'>

              <h2>ABI (Knöchel-Arm-Index)</h2>
                <div className='text-style'>
                Der Knöchel-Arm-Index (Ancle-Brachial-Index, ABI) ist eine Screeningmethode, die bei Patienten, bei denen eine 
                Schaufensterkrankheit (periphere arterielle Verschlusskrankheit, PAVK) vermutet wird, zwecks Diagnosestellung,
                Schweregrad- und Prognoseeinschätzung eingesetzt wird.
                </div>
            </div>

            <div className='flex-item'>
              <h2>24 Stunden RR (24 Stunden Blutdruckmessung)</h2>
                <div className='text-style'>
                  Die 24 Stunden RR-Messung ist nicht invasiv und vollkommen schmerzfrei. Dem Patienten wird in der Praxis 
                  eine Blutdruckmanschette am Oberarm angelegt, welche mit einem Computer verbunden ist. Dieser führt in 
                  regelmäßigen Zeitabständen Messungen durch, am Tag sowie in der Nacht. <strong>(Privatleistung für ÖGK-Versicherte)</strong>
                </div>
            </div>

            <div className='flex-item'>
              <h2>Blutabnahme / Blutdiagnostik (nach ärztlicher Anordnung!)</h2>
                <div className='text-style'>
                Die Blutuntersuchung ist eine der hilfreichsten diagnostischen Methoden in der Medizin. Dabei werden Inhaltsstoffe 
                sowie die Anzahl und Beschaffenheit der einzelnen festen Bestandteile des Blutes mit Referenzwerten und 
                Normalbereichen verglichen. Lesen Sie alles Wichtige über die verschiedenen Arten der Blutuntersuchung, wie sie 
                durchgeführt wird und wann sie notwendig ist. 
                </div>
            </div>

            <div className='flex-item'>
              <h2>Diabetes mellitus (Blutzuckereinstellung)</h2>
                <div className='text-style'>
                NEUDIAGNOSE Gute Glukoseeinstellung erreichen. Um die Blutzuckerwerte im Zielbereich zu halten und das Risiko
                von Folgeerkrankungen zu senken, müssen dem Körper genau dosierte Insulinmengen zugeführt werden.
                </div>
            </div>

            <div className='flex-item'>
              <h2>Schilddrüse (Medikamenteneinstellung)</h2>
                <div className='text-style'>
                Macht die Diagnose eine medikamentöse Therapie erforderlich, beginnt die Einstellung des Patienten auf die 
                richtige Dosis. Ausschlaggebender Parameter dafür ist meist der TSH-Wert, der in regelmäßigen Abständen überprüft 
                wird. Liegt dieser im gewünschten Bereich, gilt der Patient als „gut eingestellt“
                </div>
            </div>

            <div className='flex-item'>
              <h2>OP-Freigabe (Überweisung notwendig)</h2>
                <div className='text-style'>
                Üblicherweise wird von Spitälern vor einer geplanten Operation eine Operationsfreigabe von einem Internisten 
                verlangt. Dazu werden ein EKG, eine Blutuntersuchung sowie eine Lungenfunktionstest durchgeführt. Weiters wird 
                vom Internisten entschieden, ob allenfalls Medikamente (die der Patient regelmäßig einnimmt) vor der 
                Operation abgesetzt werden müssen. Die OP-Freigabe sollte innerhalb von 14 Tagen vor der geplanten Operation 
                erteilt werden. Bitte geben Sie uns daher im Zuge der Terminvereinbarung den OP-Termin bekannt, damit wir die
                Operationsfreigabe entsprechend einplanen können.
                </div>
            </div>

            <div className='flex-item'>
              <h2>Mutter-Kinde-Pass-Untersuchung (internistisch)</h2>
                <div className='text-style'>
                Eine internistische Begutachtung (der Mutter) ist im Rahmen der Mutter-Kind-Pass-Untersuchung in der 17. bis 20. 
                Schwangerschaftswoche vorgesehen. Es werden dabei u.a. das Herz, die Lunge, der Blutdruck etc. begutachtet.
                </div>
            </div>

          </div>

          <h1 className='headingStyle'>Private Leistungen</h1>
          <div className='flex-container'><br />

            <div className='flex-item'>
              <h2>Führerscheinuntersuchung </h2>
                <div className='text-style'>
                  Verlängerung der Lizenz für die Bezirkshauptmannschafft.
                </div>
            </div>

            <div className='flex-item'>
              <h2>Diverse Aufnahmeverfahren</h2>
                <div className='text-style'>
                  Internistische Untersuchungen inklusive Ergometrie für Aufnahmeverfahren wie z.B Sportschule, Polizeiprüfung, usw.  
                </div>
            </div>

          </div>

      </div>
    </div>
  );
}
